<template>
    <div class="up-down" @click="showPopup(item)" :style="{width: width === 1 ? '33.33%' : ''}">
        <div class="up" v-if="item">
            <div class="moreBox" v-if="item.type" @click.stop="moveLR(3,item.type)">
                <p>查看全部</p>
            </div>
            <img
                v-else
                style="width: 118px;height: 158px;"
                :src="item.path"
                alt="">
        </div>
        <div class="down">
            <div style="width: 6.5px; height: 6.5px" class="pulse svelte-tgp09d is-relative"></div>
            <div style="margin-left: 10px;">{{ 100 }} <span class="txt">人在玩</span></div>
        </div>
        <Modal
            :footer-hide="true"
            v-model="modal"
            :closable="false">
            <div class="popup-box">
                <div class="top">
                    <div class="cover">
                        <img :src="popInfo.path" alt="">
                    </div>
                    <div class="txt">
                        <div class="txt1">{{ popInfo.gameName }}</div>
                        <div class="txt2">{{ popInfo.gameFactory }}</div>
                        <div class="star" @click="submitGameId(7)">
                            <img v-if="!star" src="../assets/common/star.svg" alt="SVG"
                                 style="width: 14px;margin-top: 20px">
                            <img v-else src="../assets/common/star-active.svg" alt="SVG"
                                 style="width: 14px;margin-top: 20px">
                            收藏
                        </div>
                    </div>
                </div>
                <div class="btn-list">
                    <Button class="btn" type="error" @click="modal=!modal">取消</Button>
                    <Button v-if="member" class="btn" type="primary" @click="getGameUrl">
                        <Icon type="ios-play"/>
                        开始
                    </Button>
                    <Button v-else class="btn" type="primary" @click="getGameUrl">
                        登陆
                    </Button>
                </div>
            </div>
        </Modal>
    </div>
</template>
<script>
import members from "@/views/members.vue";

export default {
    name: "",
    props: ['item', 'width'],
    data() {
        return {
            star: false,
            modal: false,
            popInfo: {},
        }
    },
    mounted() {
        let user = localStorage.getItem("MEMBER")
        if (user && user !== 'null') {
            let ids = JSON.parse(user).lastLoginDevice
            if (ids.includes(this.item.id)) {
                this.star = true
            }
        }
    },
    computed: {
        member() {
            return this.$store.state.member
        }
    },
    methods: {
        moveLR(num, gameCategory) {
            this.$parent.moveLR(num, gameCategory)
        },
        showPopup(item) {
            this.modal = !this.modal
            this.popInfo = item
        },
        getGameUrl() {
            let data
            if (!localStorage.getItem('TOKEN') || localStorage.getItem('TOKEN') == 'null') {
                this.$router.push({path: '/login'})
                return
            }
            if (this.item.gameFactory === "JILI") {
                /*
                厂商 pp
                symbol   游戏id
                language ： en   游戏id

                英文 en-US
                越南文 vi-VN
                简体中文 zh-CN
                * */
                data = {
                    Token: localStorage.getItem('TOKEN'),
                    GameId: Number(this.popInfo.gameId),
                    Lang: this.$i18n.locale,
                    // remark: members.remark
                }
                this.$http.get(this.host + '/jili/LoginWithoutRedirect', {params: data}).then(response => {
                    const res = response.body
                    console.log(res)
                    // window.location.href = res.gameURL + '&remark=USDT'
                    this.submitGameId(6)  //游戏记录
                    this.$router.push({path: '/gamepage', query: {name: this.popInfo.gameName, fcUrl: res.Data}})
                })
            }
            if (this.item.gameFactory === "Pragmatic Play") {
                /*
                厂商 pp
                symbol   游戏id
                language ： en
                * */
                data = {
                    symbol: this.popInfo.gameId,
                    language: "en",
                    remark: this.member.remark
                }
                this.$http.post(this.host + '/pp/game/url', data).then(response => {
                    const res = response.body
                    // window.location.href = res.gameURL + '&remark=USDT'
                    this.submitGameId(6)  //游戏记录
                    this.$router.push({path: '/gamepage', query: {name: this.popInfo.gameName, fcUrl: res.gameURL}})
                })
            }
            if (this.item.gameFactory === "JDB") {
                /*
                厂商 JDB
                lang ： en  cn  vn
                gType 游戏类型
                mType 机台类型
                * */
                let lang
                if (this.$i18n && this.$i18n.locale) {
                    if (this.$i18n.locale === 'en-US') lang = 'en'
                    if (this.$i18n.locale === 'zh-CN') lang = 'cn'
                    if (this.$i18n.locale === 'vi-VN') lang = 'vn'
                }
                let params = {
                    lang,
                    gType: this.popInfo.gtype,
                    mType: this.popInfo.gameId,
                    ccy:this.member.remark
                }
                this.$http.get(this.host + '/jdb/getGameUrl', {params}).then(response => {
                    const res = response.body
                    // window.location.href = res.gameURL + '&remark=USDT'
                    this.submitGameId(6)  //游戏记录
                    this.$router.push({path: '/gamepage', query: {name: this.popInfo.gameName, fcUrl: res.path}})
                })
            }
        },
        submitGameId(type) { //6游戏记录  7收藏
            let unstrt = (type === 7 && this.star)
            let user = JSON.parse(localStorage.getItem('MEMBER'))
            let item, param
            if (type === 6) {
                item = user.registerDevice
                if (item.includes(this.popInfo.id)) {  //游戏记录存在id
                    return
                }
            } else if (type === 7) {
                item = user.lastLoginDevice
                if (item.includes(this.popInfo.id)) {  //已收藏  则取消收藏
                    item = item.replace(this.popInfo.id, '')
                    item = item.replace(/,+$/, '');  //去掉末尾的 ，
                }
            }
            if (item.trim() !== '') {  //非空的时候
                if (!unstrt) {//操作不是取消收藏
                    //系统只存20个
                    let itemArr = item.split(",")
                    if (itemArr.length >= 20) {
                        itemArr.shift()
                        item = itemArr.join()
                    }
                    item += `,${this.popInfo.id}`
                }
            } else {  //当前为空
                item += `${this.popInfo.id}`
            }
            if (type === 6) {
                param = {
                    registerDevice: item
                };
            } else if (type === 7) {
                param = {
                    lastLoginDevice: item
                };
            }
            this.$http.post(this.host + this.api.uc.updatePersonalInfo, param).then(response => {
                let resp = response.body;
                if (resp.code == '0000') {
                    if (type === 7) this.star = !this.star
                    this.$store.commit("setMember", resp.data);
                } else {
                    this.$Message.error(resp.mesg);
                }
            });
        },
        // getMemberInfo() {
        //     let self = this;
        //     this.$http
        //         .post(this.host + this.api.uc.memberInfo)
        //         .then(response => {
        //             var resp = response.body;
        //             if (resp.code == "0000") {
        //                 localStorage.setItem("MEMBER", JSON.stringify(null));
        //                 self.$store.commit("setMember", resp.data);
        //             } else {
        //                 self.$Message.error(resp.mesg);
        //             }
        //         });
        // },
    }
}
</script>


<style scoped lang="scss">
.up-down {
    padding: 10px 0;
    width: 16.666%;
    display: flex;
    flex-direction: column;

    .up > img {
        border-radius: 6px;
        box-shadow: 0 4px 6px -1px rgba(27, 23, 23, .2), 0 2px 4px -1px rgba(0, 0, 0, .12);
    }

    .up {
        .moreBox {
            width: 118px;
            height: 158px;
            background-image: url("../assets/common/moreGame.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;

            & > p {
                color: #fff;
                font-size: 20px;
                font-weight: 500;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .down {
        color: #fff;
        text-align: left;
        font-size: 12px;
        display: flex;
        justify-content: start;
        align-items: center;
        font-weight: bold;
        padding: 0 10px;

        .green {
            width: 6px;
            height: 6px;
            background-color: #1fff20;
            border-radius: 6px;
            margin-right: 4px;
        }

        .txt {
            color: #bab1d3;
        }
    }
}

div.svelte-tgp09d {
    --green-400: #1fff20;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    z-index: 1;
    background: var(--green-400)
}

div.svelte-tgp09d:not(.is-relative) {

    position: absolute;
    transform: translate(-50%, -50%) scale(1) !important
}

div.scale-up.svelte-tgp09d {
    animation: svelte-tgp09d-ScaleUp .2s ease-out
}

div.pulse.svelte-tgp09d {
    margin-left: 2px;
    animation: svelte-tgp09d-pulse 1s infinite ease-out
}

@keyframes svelte-tgp09d-ScaleUp {
    0% {
        transform: translate(-50%, -50%) scale(0)
    }
}

@keyframes svelte-tgp09d-pulse {
    0% {
        box-shadow: 0 0 #00e70166
    }

    30% {
        box-shadow: 0 0 2px 2px #00e70166
    }

    70% {
        box-shadow: 0 0 2px 2px #00e70166
    }

    to {
        box-shadow: 0 0 #00e70166
    }
}

.popup-box {
    display: flex;
    flex-direction: column;

    .top {
        display: flex;

        .cover {
            margin-left: 10px;
            width: 30%;

            & > img {
                border-radius: 8px;
                width: 100%;
            }
        }

        .txt {
            color: #fff;
            margin-left: 20px;
            display: flex;
            justify-content: start;
            flex-direction: column;

            .txt1 {
                font-size: 16px;
            }

            .txt2 {
                color: #ccc;
            }

            .star:active {
                transform: scale(1.1);
                opacity: .5;
            }
        }
    }

    .btn-list {
        display: flex;
        justify-content: space-around;

        .btn {
            height: 40px;
            width: 48%;
        }
    }
}

/deep/ .ivu-modal-content {
    margin-top: 160px;
    border-radius: 10px;
    background-color: #1A2C38;
}

/deep/ .ivu-modal-mask {
    background-color: rgba(0, 0, 0, .8) !important;
}
</style>
